import { useState, useEffect } from "react";

import FireShoppingBag from "../Components/FireShoppingBag";
import FireLogo from "../Components/FireLogo";
import FireDrawer from "../Components/FireDrawer";
import FirePopover from "../Components/FirePopover";

import { helpPages } from "../Components/HelpNavbar";
import { cn } from "@/lib/utils";

import {
  ShoppingBag,
  Heart,
  Search as SearchIcon,
  Menu as MenuIcon,
  Box as BoxIcon,
  Gift,
  CircleHelp,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { Link, router, usePage } from "@inertiajs/react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/Components/ui/dropdown-menu";

type subNavItemTypes = {
  subItemName: string;
  href: string;
};

type mainMenuTypes = {
  title: string;
  subItems: subNavItemTypes[];
};

type menuProps = {
  name: string;
  href: string;
  subNav: mainMenuTypes[];
};

function Navbar() {
  const { props } = usePage<{
    auth: {
      user: {
        is_admin: number;
      };
    };
  }>();

  const [mobileNavPage, setMobileNavPage] = useState<number>(1);
  const [mainNavItems, setMainNavItems] = useState<mainMenuTypes[]>([]);
  const [subNavItems, setSubNavItems] = useState<subNavItemTypes[]>([]);
  const [subItemTitles, setSubItemTitles] = useState<string[]>([]);
  const [isLogged, setIsLogged] = useState<boolean>();
  const [isAdmin, setIsAdmin] = useState<boolean>();

  const [isProps, setIsProps] = useState<any>();
  const [currentDiv, setCurrentDiv] = useState(0);

  useEffect(() => {
    if (props.auth.user) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }, []);

  useEffect(() => {
    if (props?.auth?.user?.is_admin) {
      setIsProps(props?.auth);
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [isLogged]);

  useEffect(() => {
    console.log("props.auth", props.auth);
    if (!props.auth) return;
    setIsProps(props.auth?.user);
  }, [props.auth]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDiv((prev) => (prev === 0 ? 1 : 0));
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const handleLogout = () => {
    router.post("logout");
    window.location.reload();
  };

  const menuData: menuProps[] = [
    {
      name: "duvar sanatı",
      href: "#",
      subNav: [
        {
          title: "Featured",
          subItems: [
            {
              subItemName: "New Arrivals",
              href: "#",
            },
            {
              subItemName: "Best Sellers",
              href: "#",
            },
            {
              subItemName: "from a Set of 2",
              href: "#",
            },
            {
              subItemName: "from a Set of 3",
              href: "#",
            },
            {
              subItemName: "from a Set of 4",
              href: "#",
            },
          ],
        },
        {
          title: "Collections",
          subItems: [
            {
              subItemName: "Altered Prints",
              href: "#",
            },
            {
              subItemName: "Collage Prints",
              href: "#",
            },
            {
              subItemName: "Exhibition Prints",
              href: "#",
            },
            {
              subItemName: "Feminist Prints",
              href: "#",
            },
            {
              subItemName: "Japanese Prints",
              href: "#",
            },
            {
              subItemName: "Lyrics Prints",
              href: "#",
            },
            {
              subItemName: "Masterpieces",
              href: "#",
            },
          ],
        },
        {
          title: "Showcased",
          subItems: [
            {
              subItemName: "Everyday Quotes Prints",
              href: "#",
            },
            {
              subItemName: "Iconic Collage Portraits Prints",
              href: "#",
            },
            {
              subItemName: "Portraits of Beauties Prints",
              href: "#",
            },
            {
              subItemName: "Iconic Exhibition Celebrities",
              href: "#",
            },
          ],
        },
        {
          title: "Colors",
          subItems: [
            {
              subItemName: "Black & White Prints11",
              href: "#",
            },
          ],
        },
      ],
    },
    {
      name: "hayat",
      href: "#",
      subNav: [
        {
          title: "Coffee Mugs",
          subItems: [
            {
              subItemName: "New Arrivals",
              href: "#",
            },
            {
              subItemName: "Best Sellers",
              href: "#",
            },
            {
              subItemName: "from a Set of 2",
              href: "#",
            },
            {
              subItemName: "from a Set of 3",
              href: "#",
            },
            {
              subItemName: "from a Set of 4",
              href: "#",
            },
          ],
        },
        {
          title: "Categories",
          subItems: [
            {
              subItemName: "Abstract",
              href: "#",
            },
            {
              subItemName: "Black and White",
              href: "#",
            },
            {
              subItemName: "Geometric",
              href: "#",
            },
            {
              subItemName: "Pop Art",
              href: "#",
            },
            {
              subItemName: "Typography",
              href: "#",
            },
            {
              subItemName: "Urban Art",
              href: "#",
            },
          ],
        },
        {
          title: "Collections",
          subItems: [
            {
              subItemName: "A Week in New York",
              href: "#",
            },
            {
              subItemName: "Everyday Quotes",
              href: "#",
            },
            {
              subItemName: "Stay Golden",
              href: "#",
            },
            {
              subItemName: "Holidays",
              href: "#",
            },
            {
              subItemName: "Christmas",
              href: "#",
            },
            {
              subItemName: "Fathers Day",
              href: "#",
            },
          ],
        },
        {
          title: "Holidays",
          subItems: [
            {
              subItemName: "Christmas",
              href: "#",
            },
            {
              subItemName: "Fathers Day",
              href: "#",
            },
            {
              subItemName: "Valentines Day",
              href: "#",
            },
          ],
        },
        {
          title: "Themes",
          subItems: [
            {
              subItemName: "Japanese",
              href: "#",
            },
            {
              subItemName: "Quote",
              href: "#",
            },
            {
              subItemName: "Retro",
              href: "#",
            },
            {
              subItemName: "Skull",
              href: "#",
            },
          ],
        },
      ],
    },
    {
      name: "teknoloji",
      href: "#",
      subNav: [],
    },
    {
      name: "giyim",
      href: "#",
      subNav: [],
    },
    {
      name: "koleksiyonlar",
      href: "#",
      subNav: [],
    },
  ];

  const getMainNav = (subNav: mainMenuTypes[], mainMenuTitle: string) => {
    setMainNavItems(subNav);
    setSubItemTitles([mainMenuTitle]);
  };

  const getSubNavItem = (subItems: subNavItemTypes[], subItemTitle: string) => {
    setSubItemTitles((prev) => [...prev, subItemTitle]);
    setSubNavItems(subItems);
  };

  return (
    <>
      <div className="hidden flex-1 items-center justify-end border-b border-[#303f50] bg-fire-blue sm:items-stretch lg:flex lg:px-14">
        {isLogged && !isAdmin ? (
          <div className="flex items-center text-white">
            <div className="flex items-baseline space-x-3 text-center">
              <a
                href="/"
                className="py-2 text-xs font-semibold capitalize"
                aria-current="page">
                Hediye Kartı
              </a>
              <span className="mx-2">|</span>
              <a
                href="/"
                className="py-2 text-xs font-semibold capitalize"
                aria-current="page">
                Yardım
              </a>
            </div>
            <span className="mx-2">|</span>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className="cursor-pointer py-2 text-xs font-semibold capitalize">
                  Merhaba, {isProps?.name}
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56" align="end">
                <DropdownMenuLabel className="cursor-pointer text-lg font-bold">
                  <Link href={route("account")}>Profil</Link>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuRadioGroup>
                  <DropdownMenuItem className="cursor-pointer">
                    <Link href={route("my.orders")}>Siparişler</Link>
                  </DropdownMenuItem>

                  <DropdownMenuItem className="cursor-pointer">
                    <Link href={route("my.favorites")}>Favoriler</Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem className="cursor-pointer">
                    <Link href={route("address")}>Adresler</Link>
                  </DropdownMenuItem>

                  <DropdownMenuItem onClick={handleLogout}>
                    Çıkış Yap
                  </DropdownMenuItem>
                </DropdownMenuRadioGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        ) : (
          <div className="flex items-baseline space-x-3 text-center text-white">
            <a
              href="#"
              className="py-2 text-xs font-semibold capitalize text-white"
              aria-current="page">
              Hediye kartı
            </a>
            <span className="link-spacer">|</span>
            <FirePopover
              trigger="hover"
              contentClassName="w-full lg:w-[200px] mt-[8px] -right-2 bg-white text-black"
              content={
                <div className="px-[20px] py-[20px]">
                  <p className="mb-[20px] text-start text-[16px] font-bold leading-[20px]">
                    Yardım
                  </p>
                  <ul className="space-y-1 text-start">
                    {helpPages.length > 0 &&
                      helpPages.map((helpItem, index) => {
                        return (
                          <li key={index}>
                            <a
                              className={`text-[14px] font-[500] leading-[21px] ${helpItem.active ? "text-black hover:text-fire-gray" : "text-fire-gray"} hover:text-black`}
                              href={helpItem.href}>
                              {helpItem.label}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              }>
              <a
                href="#"
                className="py-2 text-xs font-semibold capitalize text-white"
                aria-current="page">
                Yardım
              </a>
            </FirePopover>
            <span className="link-spacer">|</span>
            <a
              href={route("register")}
              className="py-2 text-xs font-semibold capitalize text-white"
              aria-current="page">
              Kayıt ol
            </a>
            <span className="link-spacer">|</span>
            <a
              href={route("login")}
              className="py-2 text-xs font-semibold capitalize text-white"
              aria-current="page">
              Giriş yap
            </a>
          </div>
        )}
      </div>

      <nav className="sticky top-0 z-10 mx-auto bg-fire-blue">
        <div className="mx-2 grid h-[60px] grid-cols-3 items-center justify-between xs:grid-cols-3 xs:px-0 sm:grid-cols-3 sm:px-6 md:grid-cols-3 lg:mx-4 lg:h-auto lg:grid-cols-3 lg:px-8">
          {/** Mobil menü */}
          <div className="inset-y-0 left-0 flex items-center lg:hidden">
            <FireDrawer
              buttonContent={
                <MenuIcon size={32} className="cursor-pointer text-white" />
              }
              backgroundColor="white"
              side="left"
              padding="px-[15px] py-[5px]"
              hiddenCloseIcon={mobileNavPage > 1}>
              <div
                className={cn(
                  "mt-[50px] px-[8px]",
                  mobileNavPage === 1
                    ? "block animate-slideOverIn"
                    : "hidden animate-slideOverOut opacity-0"
                )}>
                <div className="grid space-y-2">
                  {menuData.length > 0 &&
                    menuData.map((item, index) => {
                      const isSubItems = item.subNav.length > 0;

                      return (
                        <div
                          key={index}
                          className="flex cursor-pointer items-center">
                          <a
                            href={isSubItems ? undefined : item.href}
                            className="w-full text-[22px] font-[600] capitalize text-[#000000]"
                            aria-current="page"
                            onClick={() => {
                              if (isSubItems) {
                                setMobileNavPage(2);
                                getMainNav(item.subNav, item.name);
                              }
                            }}>
                            {item.name}
                          </a>
                          {isSubItems && <ChevronRight fontSize={28} />}
                        </div>
                      );
                    })}
                </div>
                <div className="my-9 grid">
                  <div className="pb-7">
                    <p className="text-[17px] font-[500] text-gray-600">
                      Kayıt ol ve ilham alın
                    </p>
                  </div>
                  <div className="flex flex-1 flex-wrap gap-2">
                    <a
                      className="mr-2 w-fit rounded-full border border-black bg-black px-[35px] py-[12px] text-[15px] font-[700] text-[#ffffff]"
                      href={route("register")}>
                      Kayıt ol
                    </a>
                    <a
                      className="border-gray w-fit rounded-full border bg-white px-[35px] py-[12px] text-[15px] font-[700] text-[#000000]"
                      href={route("login")}>
                      Giriş yap
                    </a>
                  </div>
                </div>
                <div className="mt-[60px] grid space-y-3">
                  <div className="flex items-center space-x-4">
                    <ShoppingBag />
                    <a
                      href="#"
                      className="text-md py-1 font-semibold capitalize text-black"
                      aria-current="page">
                      Sepet
                    </a>
                  </div>
                  <div className="flex items-center space-x-4">
                    <BoxIcon />
                    <a
                      href="#"
                      className="text-md py-1 font-semibold capitalize text-black"
                      aria-current="page">
                      Siparişler
                    </a>
                  </div>
                  <div className="flex items-center space-x-4">
                    <Gift />
                    <a
                      href="#"
                      className="text-md py-1 font-semibold capitalize text-black"
                      aria-current="page">
                      Hediye kartı
                    </a>
                  </div>
                  <div className="flex items-center space-x-4">
                    <CircleHelp />
                    <a
                      href="#"
                      className="text-md py-1 font-semibold capitalize text-black"
                      aria-current="page">
                      Yardım
                    </a>
                  </div>
                </div>
              </div>

              <div
                className={cn(
                  mobileNavPage === 2
                    ? "block animate-slideOverIn"
                    : "hidden animate-slideOverOut opacity-0"
                )}>
                <div
                  onClick={() => setMobileNavPage(mobileNavPage - 1)}
                  className="relative -left-3 flex cursor-pointer items-center pt-[5px] font-[700]">
                  <ChevronLeft size={31} className="w-fit p-0" /> Tümü
                </div>
                <div className="mt-[25px] px-[8px]">
                  <div className="grid space-y-3">
                    {mainNavItems.length > 0 &&
                      mainNavItems.map((subItem, subIndex) => (
                        <div
                          key={subIndex}
                          className="flex cursor-pointer items-center">
                          <a
                            key={subIndex}
                            className="w-full text-[22px] font-[600] capitalize text-[#000000]"
                            aria-current="page"
                            onClick={() => {
                              setMobileNavPage(3);
                              getSubNavItem(subItem.subItems, subItem.title);
                            }}>
                            {subItem.title}
                          </a>
                          <ChevronRight fontSize={28} />
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div
                className={cn(
                  mobileNavPage === 3
                    ? "block animate-slideOverIn"
                    : "hidden animate-slideOverOut opacity-0"
                )}>
                <div
                  onClick={() => setMobileNavPage(mobileNavPage - 1)}
                  className="relative -left-3 flex cursor-pointer items-center pt-[5px] font-[700] capitalize">
                  <ChevronLeft size={31} className="right-4" />{" "}
                  {subItemTitles[0]}
                </div>
                <div className="mt-[25px] space-y-4 px-[8px]">
                  <a className="text-[22px] font-[600] capitalize text-[#000000]">
                    {subItemTitles[1]}
                  </a>
                  <div className="grid space-y-2">
                    {subNavItems.length > 0 &&
                      subNavItems.map((subItem, index) => (
                        <a
                          key={index}
                          href={subItem.href}
                          className="text-[16px] font-[400] capitalize text-[#000000]"
                          aria-current="page">
                          {subItem.subItemName}
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            </FireDrawer>
          </div>
          <div className="flex flex-shrink-0 items-center justify-center py-2 lg:justify-start">
            <FireLogo size="normal" />
          </div>
          <FirePopover
            trigger="hover"
            popupClassName="!w-full hidden lg:flex"
            contentClassName={`mt-[11px] !w-[99vw] flex justify-center space-x-8 py-4 overflow-hidden bg-[#ffffff] text-sm shadow-xl`}
            hasContent={mainNavItems.length > 0}
            content={
              <>
                {mainNavItems.map((item, index) => (
                  <div className="p-4" key={index}>
                    <h3 className="mb-4 text-[18px] font-semibold">
                      {item.title}
                    </h3>
                    <ul>
                      {item.subItems.map((SubItem, index) => (
                        <li key={index} className="mb-1">
                          <a href={SubItem.href}>{SubItem.subItemName}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </>
            }>
            <div className="float-end hidden w-full flex-1 items-center justify-center sm:items-stretch lg:flex">
              <div className="flex space-x-5">
                {menuData.length > 0 &&
                  menuData.map((item, index) => (
                    <a
                      key={index}
                      onMouseOver={() => getMainNav(item.subNav, item.name)}
                      href={item.href}
                      className="w-max border-b-2 border-transparent py-2 text-base font-semibold capitalize text-white hover:border-b-white"
                      aria-current="page">
                      {item.name}
                    </a>
                  ))}
              </div>
            </div>
          </FirePopover>

          <div className="flex items-center justify-end pl-5 sm:ml-6">
            <FireDrawer
              buttonContent={
                <SearchIcon className="cursor-pointer text-white" size={24} />
              }
              backgroundColor="fire-blue"
              side="top"
              padding="px-[15px] py-[15px]"
              margin="mt-[25px]"
              colorCloseIcon="white">
              <div className="grid grid-cols-1 lg:grid-cols-3">
                <div className="hidden lg:block">
                  <FireLogo size="normal" />
                </div>
                <div className="mx-4 grid">
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <SearchIcon className="cursor-pointer" size={16} />
                    </div>
                    <input
                      type="text"
                      name="search"
                      id="search"
                      className="block w-full rounded-full border-none bg-gray-100 py-1.5 pl-10 text-gray-900 placeholder:text-gray-400 focus:border-transparent focus:bg-gray-200 focus:ring-0"
                      placeholder="Arama"
                    />
                  </div>
                  <div className="mt-4">
                    <p className="text-md font-semibold text-gray-400">
                      Popüler arama terimleri
                    </p>
                    <ul className="mt-4 space-y-3">
                      <li className="mb-1">
                        <a href="#" className="font-semibold text-white">
                          Mug
                        </a>
                      </li>
                      <li className="mb-1">
                        <a href="#" className="font-semibold text-white">
                          Pop art
                        </a>
                      </li>
                      <li className="mb-1">
                        <a href="#" className="font-semibold text-white">
                          İphone
                        </a>
                      </li>
                      <li className="mb-1">
                        <a href="#" className="font-semibold text-white">
                          Prints
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </FireDrawer>
            <Heart
              className="mx-4 cursor-pointer text-white lg:mx-5"
              size={24}
            />
            <FireShoppingBag />
          </div>
        </div>
      </nav>
      <div className="relative block h-11 overflow-hidden text-center">
        <div
          className={cn(
            "absolute h-11 w-full content-center bg-[#dbe8f2]",
            currentDiv === 0
              ? "visible animate-slideUp"
              : "invisible animate-slideDown opacity-0"
          )}>
          <p className="font-[600] [font-size:_clamp(0.7rem,1vw,0.9rem)]">
            Tüm Kredi Kartlarına Peşin Fiyatına 3 Ay Taksit İmkanı
          </p>
        </div>
        <div
          className={cn(
            "absolute h-11 w-full content-center bg-[#ebff8a]",
            currentDiv === 1
              ? "visible animate-slideUp"
              : "invisible animate-slideDown opacity-0"
          )}>
          <p className="font-[600] [font-size:_clamp(0.7rem,1vw,0.9rem)]">
            Tüm Kredi Kartlarına Peşin Fiyatına 3 Ay Taksit İmkanı
          </p>
        </div>
      </div>
    </>
  );
}

export default Navbar;
